const React = window.React = require('react');
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const HeadMetaTag = (props) => {

  const { t, i18n } = useTranslation();

  const title = `${t('headMeta01', 
    'StellarSpace News (news of cryptocurrency, markets, blockchain, other, and reward points)'
  )}`;

  const description = `${t('headMeta02', 
    'News from various news sources about cryptocurrencies, markets, blockchain, others, and reward points info'
  )}`;

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </Fragment>
  );
}

export default HeadMetaTag;