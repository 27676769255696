import React, { useEffect, useState, Fragment } from 'react';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

// import { useStore } from '../../../hook-store/store';
import { useStore } from '../../../../StellarSpaceNews/hook-store/store';

import classes from './RewardsSearch.module.css';

const searchBoxStyle = {
    height: '44px',
    height: '40px',
    border: '1px solid #dfe1e5',
    // border: "1px solid gray",
    // borderRadius: "24px",
    borderRadius: '6px',
    backgroundColor: 'white',
    boxShadow: 'rgba(32, 33, 36, 0.28) 0px 1px 6px 0px',
    hoverBackgroundColor: '#eee',
    color: '#212121',
    // fontSize: "16px",
    fontSize: '20px',
    fontFamily: 'Arial',
    iconColor: 'grey',
    lineColor: 'rgb(232, 234, 237)',
    placeholderColor: 'grey',
    // clearIconMargin: '3px 14px 0 0',
    clearIconMargin: '3px 5px 0 0',
    // searchIconMargin: '0 0 0 16px',
    searchIconMargin: '0 0 0 5px',
    zIndex: '200',
};

function RewardsSearch(props) {
    console.log('ReactSearchAutoComp.js-props', props);
    const { rewards, setSelectedReward } = props;

    const [store, dispatch] = useStore();

    // const { usersForAdmin } = store.adminStore;
    // console.log(store);
    const list = rewards.map((element, index) => {
        return {
            ...element,
            id: index,
            displayName: `${element.market_key.asset1_code} - ${element.market_key.asset2_code}`,
        };
    });

    useEffect(() => {}, []);

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.

        // console.log(string, results);
        //

        if (!string) {
            setSelectedReward(null);
            // setSearchSelectedItem(null);
            // dispatch('SET_SEARCH_SELECTEDITEM', null);
        }
    };

    const handleOnHover = (result) => {
        // the item hovered
        // console.log(result);
    };

    const handleOnSelect = (item) => {
        // the item selected
        console.log('selected item: ', item);
        setSelectedReward(item);
        // setSearchSelectedItem(item);
        // dispatch('SET_SEARCH_SELECTEDITEM', item);
        // dispatch('SET_ADMIN_SELECTEDUSER', item);
    };

    const handleOnFocus = () => {
        console.log('Focused');
        // console.log(items);
    };

    const handleOnClear = () => {
        console.log('Cleared');
        setSelectedReward(null);
        // console.log(items);
        // setSearchSelectedItem(null);
        // dispatch('SET_SEARCH_SELECTEDITEM', null);
    };

    const formatResult = (item) => {
        // const marketKey = item.market_key;
        return (
            <>
                {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
                {/* <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span> */}
                {/* <span style={{ display: 'block', textAlign: 'left' }}>adPlaceId: {item.adPlaceId}</span> */}
                <span style={{ display: 'block', textAlign: 'left', paddingBottom: '15px' }}>
                    {item.displayName}
                </span>
                {/* <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem" }}>
          code2: <strong></strong>
        </span> */}
                {/* <span style={{ display: 'block', textAlign: 'left',paddingBottom: "0.5rem" }}>type: <strong>{item.adType}</strong></span> */}
            </>
        );
    };

    return (
        <Fragment>
            <div
                className={classes.adHostSearch}
                // style={{ width: 400 }}
            >
                <ReactSearchAutocomplete
                    // items={items}
                    items={list}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    onClear={handleOnClear}
                    // autoFocus
                    formatResult={formatResult}
                    fuseOptions={{ keys: ['market_key.asset1_code', 'market_key.asset2_code'] }}
                    resultStringKeyName="displayName"
                    placeholder="search asset code"
                    styling={searchBoxStyle}
                />
            </div>
        </Fragment>
    );
}

export default RewardsSearch;
