// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bRrxNs8FfS_G1B9EAgxO {
  text-align: center;
}

.kua8lDPdQ64szllYLTYd {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.25rem;
}

.soQOWKQkgGNBC7FFQkGv {
  font-weight: bolder;
  margin-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.Yb7myxntV7Pb_F4Kjlov {
  margin: 0.25rem 0.1rem;
}

.Z9E5B5QOd9JsqX3X2s4d {
  text-decoration: none;
  color: var(--color);
}

.FfNwqfATo_7s79pHNttS {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./pages/StellarSpaceNews/components/AdElements/GroupRightElements/RightContents.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".rightContentsImageContainer {\n  text-align: center;\n}\n\n.rightContentsTitle {\n  font-size: 1.25rem;\n  font-weight: bold;\n  text-align: center;\n  margin-bottom: 0.25rem;\n}\n\n.rightContentsText {\n  font-weight: bolder;\n  margin-bottom: 0.25rem;\n  margin-bottom: 0.25rem;\n}\n\n.rightContentsImage {\n  margin: 0.25rem 0.1rem;\n}\n\n.groupTalkRightElementLink {\n  text-decoration: none;\n  color: var(--color);\n}\n\n.groupTalkRightElementLinkContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightContentsImageContainer": `bRrxNs8FfS_G1B9EAgxO`,
	"rightContentsTitle": `kua8lDPdQ64szllYLTYd`,
	"rightContentsText": `soQOWKQkgGNBC7FFQkGv`,
	"rightContentsImage": `Yb7myxntV7Pb_F4Kjlov`,
	"groupTalkRightElementLink": `Z9E5B5QOd9JsqX3X2s4d`,
	"groupTalkRightElementLinkContainer": `FfNwqfATo_7s79pHNttS`
};
export default ___CSS_LOADER_EXPORT___;
