// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ILOQrpaakuLLbUul0j4W {
  /* font-size: "45px";  */
  color: "#99CCFF";
}`, "",{"version":3,"sources":["webpack://./pages/StellarSpaceNews/components/header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,gBAAgB;AAClB","sourcesContent":[".headerLogo {\n  /* font-size: \"45px\";  */\n  color: \"#99CCFF\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerLogo": `ILOQrpaakuLLbUul0j4W`
};
export default ___CSS_LOADER_EXPORT___;
