// const React = window.React = require('react');
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import HeaderModal from './HeaderModal.jsx';
// import SampleImage from '../../for-dist/images.json';
import SampleImage from '../../images/images.json';
// import { url } from './FbFederation.jsx';
// import ThreeLine from '../../for-dist/three-line-50.png';

// import '../../DarkMode.css';
import classes from './Header.module.css'


const Header = (props) => {
  console.log('HEADER-props', props);

  const { t, i18n } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [profilePictureData, setProfilePictureData] = useState('');

  
  const showModalHandler = () => {
    if (showModal) {
      hideModalHandler();
    } else {
      setShowModal(true);
    }
    // setShowModal(!showModal);
  }
  const hideModalHandler = () => {
    setShowModal(false);
    setShowModal2(false);
  }
  const showModal2Handler = () => {
    setShowModal2(!showModal2);
  }

  const changeLanguage = (input) => {
    i18n.changeLanguage(input);
    localStorage.setItem('userSelectLng', input);
  }





  let baseModalStyle;
  if (showModal) {
    baseModalStyle = {
      position: "fixed",
      // width: "30%",
      width: "60%",
      // height: "30%",
      left: "20%",
      top: "8vh",
      background: "white",
      borderRadius: "5px",
      zIndex: "500",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
      fontSize: "",
      fontWeight: "bolder",
      // opacity: props.showModal ? "1" : "0"
      padding: "10px",
    };
  }

  return (

  <div className="HeaderBackBack">

    <HeaderModal
      showModal={showModal}
      showModal2={showModal2}
      showModal2Handler={showModal2Handler}
      hideModalHandler={hideModalHandler}
      fbUser={props.fbUser}
      fbUserStateHandler={props.fbUserStateHandler}
      d={props.d}
    />

    <div className="so-back HeaderBack">
      <div className="so-chunk Header" style={{ maxWidth: "1080px", paddingLeft: "0px", paddingRight: "0px" }}>
        <nav style={{ display:"flex", alignItems: "center", justifyContent: "space-between", height: "50px", width: "100%", background:"" }} className="Header__nav">
            <span className={classes.headerLogo} 
              style={{fontSize:"20px", fontWeight:"bolder", color: "#99CCFF", marginLeft:"0.5rem"}}
            > 
              StellarSpace News
            </span>
            {/* <span onClick={showModalHandler}>
              header-content2
            </span> */}
            <span>
              <img src={SampleImage.threeLineMenu} className="Idbook__DisplayImage" 
                style={{ height: "45px", width: "50px", cursor:"pointer", marginRight:"0.5rem",zIndex: "" }}
                onClick={showModalHandler} alt=""
              />
            </span>
        </nav>
      </div>
    </div>
  </div>
  );

  // }
}

export default Header;




// export default class Header extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//   componentWillUnmount() {
//   }
//   render() {
//     let networkBar;
//     if (!this.props.network.isDefault) {
//       networkBar = <div className="so-back HeaderNetworkBarBack">
//         <div className="so-chunk">
//           <div className="HeaderNetworkBar">
//             <span>Horizon url: <strong>{this.props.network.horizonUrl}</strong></span>
//             <span>Network passphrase: <strong>{this.props.network.networkPassphrase}</strong></span>
//           </div>
//         </div>
//       </div>
//     }
//     return <div className="HeaderBackBack">
//       {networkBar}
//       <div className="so-back HeaderBack">
//         <div className="so-chunk Header">
//           <nav style={{height: "100px"}} className="Header__nav">
//             <a  style={{fontSize: "60px", color:"#99CCFF"}} className="Header__nav__item Header__nav__item--logo" href="#">StellarSpaceM</a>
//             <a style={{fontSize: "60px"}} className={'Header__nav__item Header__nav__item--link' + (this.props.urlParts[0] === 'exchange' ? ' is-current' : '')} href="#exchange">Exchange</a>
//             {/* <a style={{fontSize: "50px", color: "black"}} className={'Header__nav__item Header__nav__item--link' + (this.props.urlParts[0] === 'markets' ? ' is-current' : '')} href="#markets">Markets</a> */}
//             <a style={{fontSize: "60px"}} className={'Header__nav__item Header__nav__item--link' + (this.props.urlParts[0] === 'account' ? ' is-current' : '')} href="#account">Wallet</a>
//             {/* <a style={{fontSize: "40px", color: "black"}} className={'Header__nav__item Header__nav__item--link' + (this.props.urlParts[0] === 'download' ? ' is-current' : '')} href="#download">Download</a> */}
//           </nav>
//           {/* <span className="Header__version">v{window.stBuildInfo.version}</span> */}
//         </div>
//       </div>
//     </div>
//   }
// }

