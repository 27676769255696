// const React = window.React = require('react');
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Img from "react-cool-img";


import { useStore } from '../../hook-store/store';

import { createWithAdIndexList } from '../../util/ad/ad-visit';

import { fbUrl, nwUrl, ADDISPLAY_URL } from '../../App';

import '../../Island.css';
import '../../DarkMode.css';
// import '../DarkModeToggle/DarkModeToggle.css';

import classes from './NewsList.module.css';

const _ = require('lodash');



// require('dotenv').config()

// // Firebase App (the core Firebase SDK) is always required and
// // must be listed before other Firebase SDKs
// var firebase = require("firebase/app");

// // Add the Firebase products that you want to use
// require("firebase/auth");
// require("firebase/firestore");

const loadingModalStyle = {
  position: "fixed",
  width: "95%",
  height: "80%",
  left: "2.5%",
  top: "10vh",
  background: "white",
  borderRadius: "5px",
  zIndex: "500",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
  fontSize: "",
  fontWeight: "bolder",
  // opacity: props.showModal ? "1" : "0",
  // opacity: "0.8",
  opacity: "0.3",
  padding: "10px"
};

const tabStyle = {  
  borderColor: "lightgray", 
  fontWeight: "bolder", 
  // padding: "10px" ,
  padding: "7.5px" ,
  cursor: "pointer"
};

const selectedTabStyle = {
  borderColor: "lightgray", 
  fontWeight: "bolder", 
  // padding: "10px",
  padding: "7.5px",
  textDecoration: "underline darkgray 4px"
}

const AdTest = (props) => {

  // console.log('PROPS in NewsList.jsx', props);
  const { t, i18n } = useTranslation();

  const [store, dispatch] = useStore();
  const windowValues = store.windowValues;
  // console.log('windowValues', windowValues);


  return (
    <div>
        <div>ad-test-component</div>
        <div className="so-back islandBack islandBack--t">
          
    
          {/* <div className={classes.iframeContainer}>
            {windowValues && windowValues.width >= 768 && (
                <iframe id='ad-iframe-top-600x130' title='ad-iframe-top-600x130'
                  style={{width:"605px", height:"135px", overflow: 'hidden', border:"none"}}  
                  src={ADDISPLAY_URL + `?adPlaceId=${window.location.host}-1660706630287`} 
                  onLoad={() => {}}
                />
            )}
            {windowValues && windowValues.width >= 480 && windowValues.width < 768 && (
              <iframe id='ad-iframe-top-450x90' title='ad-iframe-top-450x90'
                style={{width:"460px", height:"95px", overflow: 'hidden', border:"none"}}  
                src={ADDISPLAY_URL + `?adPlaceId=${window.location.host}-1660706630286`} 
                onLoad={() => {}}
              />
            )}
            {windowValues && windowValues.width < 480 && (
              <iframe id='ad-iframe-top-300x65' title='ad-iframe-top-300x65'
                style={{width:"330px", height:"80px", overflow: 'hidden', border:"none"}}  
                src={ADDISPLAY_URL + `?adPlaceId=${window.location.host}-1660706630285`}
                onLoad={() => {}}
              />
            )}
          </div> */}


          {/* <iframe id="localhost:3000-1675410129276" 
            // title="localhost:3000-1675410129276"
            style={{width:"350px", height:"400px", border:"none"}}  
            src="http://localhost:3003?adPlaceId=localhost:3000-1675410129276"
            // onLoad={() => {}}
          /> */}

          {/* <iframe id='iframe' title='ad-iframe-300x300'
            style={{width:"330px", height:"380px", overflow: 'hidden'}}  
            src={ADDISPLAY_URL + '?adPlaceId=localhost:3000-1234543210'} 
            onLoad={() => {}}
          /> */}

          {/* <iframe id='iframe' title='ad-iframe-150x500'
            style={{width:"155px", height:"505px", overflow: 'hidden'}}  
            src={ADDISPLAY_URL + '?adPlaceId=localhost:3000-1234567890'} 
            onLoad={() => {}}
          /> */}


          {/* <iframe id='ad-iframe-top-video300' title='ad-iframe-top-video300'
            style={{width:"350px", height:"350px", overflow: 'hidden', border:"none"}}  
            src={ADDISPLAY_URL + `?adType=video300&adPlaceId=${window.location.host}-1660706630283`} 
            onLoad={() => {}}
          /> */}

          {/* <iframe id='ad-iframe-top-video300' title='ad-iframe-top-video300'
            style={{width:"350px", height:"350px", overflow: 'hidden', border:"none"}}  
            src={ADDISPLAY_URL + `?adPlaceId=localhost:3000-1660706630283`} 
            onLoad={() => {}}
          /> */}


          {/* videoWithAd-test */}
          {/* <iframe id="localhost:3000-1677479662959"
            style={{maxWidth:"98%", maxHeight:"98vh", width:"800px", height:"500px", border:"1px solid red"}}
            src="http://localhost:3003?adPlaceId=localhost:3000-1677479662959&mute=0"
            // allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
          /> */}
          {/* <iframe id="localhost:3000-1678953234980"
            style={{maxWidth:"98%", maxHeight:"95vh", width:"800px", height:"500px", border:"1px solid red"}}
            src="http://localhost:3003?adPlaceId=localhost:3000-1678953234980&mute=0"
            // allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
          /> */}

          

          {/* <div style={{height:"1000px", border:"1px solid red"}}>high-box</div> */}


          <div>stellarspace-news-test.web.app test</div>
          {/* <iframe id="stellarspace-news-test.web.app-1669276198837" title="stellarspace-news-test.web.app-1669276198837"
            style={{width:"350px", height:"350px", overflow: "hidden", border:"none"}}  
            src={ADDISPLAY_URL + "?adPlaceId=stellarspace-news-test.web.app-1669276198837"}
            onLoad={() => {}}
          /> */}
          
          {/* video-with-ad */}
          {/* <iframe id="stellarspace-news-test.web.app-1678950669368" title="stellarspace-news-test.web.app-1678950669368"
            style={{maxWidth:"100%", maxHeight:"100vh", width:"800px", height:"600px", overflow: "hidden", border:"none"}}  
            src={ADDISPLAY_URL + "?adPlaceId=stellarspace-news-test.web.app-1678950669368&mute=0"}
            onLoad={() => {}}
          /> */}



          {/* for test deply, check adPlaceId exist in db */}
          {/* <iframe id="stellarspace-news-test.web.app-1669276198837" title="stellarspace-news-test.web.app-1669276198837"
            style={{width:"650px", height:"300px", overflow: "hidden", border:"none"}}  
            src={ADDISPLAY_URL + "?adPlaceId=stellarspace-news-test.web.app-1669276198837"}
            onLoad={() => {}}
          /> */}
          

          
          {/* <iframe id='iframe' title='ad-iframe-150x500'
            style={{width:"155px", height:"505px", overflow: 'hidden'}}  
            src={ADDISPLAY_URL + '?adType=150x500&adPlaceId=localhost:3000-1234567890'} 
            onLoad={() => {}}
          />
          <iframe id='iframe' title='ad-iframe-150x500'
            style={{width:"155px", height:"505px", overflow: 'hidden'}}  
            src={ADDISPLAY_URL + '?adType=150x500&adPlaceId=localhost:3000-1234567890'} 
            onLoad={() => {}}
          /> */}


          {/* <div className={classes.testCss}>
            {windowValues && windowValues.width >= 768 && (
              <div className='body600x130Container'
              style={{margin:'0 auto'}}
              >
                <AdElementDisplay
                  adType='600x130'
                  adPlaceId='stellar-space-news-top-600x130'
                />
              </div>
            )}
            {windowValues && windowValues.width >= 480 && windowValues.width < 768 && (
              <div className='body450x90Container'
                style={{margin:'0 auto'}}
              >
                <AdElementDisplay
                  adType='450x90'
                  adPlaceId='stellar-space-news-top-450x90'
                />
              </div>
            )}
            {windowValues && windowValues.width < 480 && (
              <div 
                // className='body300x65Container'
                style={{margin:'0 auto'}}
              >
                <AdElementDisplay
                  adType='300x65'
                  adPlaceId='stellar-space-news-top-300x65'
                />
                </div>
            )}
          </div> */}
          

        
        </div>

        <div>end-of-ad-test-component</div>

    </div>
  )
}

export default AdTest;