// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a8LxD4ZhhfGyg1KY4oVL {
  width: 400px; 
  max-width: 100%;
   margin: 0 auto;
  /* padding: 0.25rem 0; */
  padding-top: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./pages/rewards/components/RewardsList/RewardsSearch/RewardsSearch.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;GACd,cAAc;EACf,wBAAwB;EACxB,mBAAmB;AACrB","sourcesContent":[".adHostSearch {\n  width: 400px; \n  max-width: 100%;\n   margin: 0 auto;\n  /* padding: 0.25rem 0; */\n  padding-top: 0.3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adHostSearch": `a8LxD4ZhhfGyg1KY4oVL`
};
export default ___CSS_LOADER_EXPORT___;
