import React from 'react';
// import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

// import AssetCard2 from './AssetCard2.jsx';
// import PathPaymentBackdrop from './PathPaymentBackdrop.jsx';

const modalStyle = {
    position: "fixed",
    width: "95%",
    left: "2.5%",
    top: "5vh",
    background: "white",
    borderRadius: "5px",
    zIndex: "500",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
    // paddingLeft: "30px"
}

const NewsModal = props => {
    console.log('NEWSModal-props', props);

    const { t, i18n } = useTranslation();

    let lnParam;
    if (
        props.i18n.language === 'en' || 
        props.i18n.language === 'Language'
    ) {
        lnParam = '-en';
    }
    if (props.i18n.language === 'hi') {
        lnParam = '-en';
    }
    if (
        props.i18n.language === 'zh-HK' 
        || props.i18n.language === 'zh-CH'
        || props.i18n.language === 'zh-CN'
        || props.i18n.language === 'zh-TW'
        || props.i18n.language === 'zh'
    )  {
        lnParam = '-' + props.i18n.language.split('-')[0];
    } if (props.i18n.language === 'es') {
        lnParam = '-' + props.i18n.language;
    }

    return (
        <div>
            <div className="NewsModal__Modal" style={{fontSize:""}}>
                <div style={{fontSize:"", padding:"20px", fontWeight:"bolder"}} 
                    onClick={() => {props.newsListSelectHandler(`bitcoin${lnParam}`); 
                    props.showModalHandler(false)
                    }}
                >
                    BTC (Bitcoin)
                </div>
                <div style={{fontSize:"", padding:"20px", fontWeight:"bolder"}} 
                    onClick={() => {
                        props.newsListSelectHandler(`ethereum${lnParam}`); 
                        props.showModalHandler(false);
                    }}
                >
                    ETH (Ethereum)
                </div>
                <div style={{fontSize:"", padding:"20px", fontWeight:"bolder"}} 
                    onClick={() => {
                        props.newsListSelectHandler(`neoBlockchain`); 
                        props.showModalHandler(false);
                    }}
                >
                    NEO
                </div>
                <div></div>
                {props.children}
            </div>
        </div>
    )
};

export default NewsModal;