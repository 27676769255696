// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qZit2ltnWlBld1sYTuU_ {
  display: flex;
  /* align-items: center; */
}

.TnqgNOYExyeAo40z11Ue {
  display: flex; 
  flex-direction: column;
  width: 235px;
  margin-left:0.25rem;
  margin-top: 0.3rem;
}

.j6l5xhczmHZ27bhRvHrG {
  width: 65px;
}

.luZkuNnek0FDQPIPANM1 {
  text-decoration: none;
  color: var(--color);
}`, "",{"version":3,"sources":["webpack://./pages/StellarSpaceNews/components/AdElements/GroupTopElements/TopBarContents.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".topBarContentsContainer {\n  display: flex;\n  /* align-items: center; */\n}\n\n.topBarContentsLeft {\n  display: flex; \n  flex-direction: column;\n  width: 235px;\n  margin-left:0.25rem;\n  margin-top: 0.3rem;\n}\n\n.topBarContentsImage {\n  width: 65px;\n}\n\n.groupTalkRightElementLink {\n  text-decoration: none;\n  color: var(--color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBarContentsContainer": `qZit2ltnWlBld1sYTuU_`,
	"topBarContentsLeft": `TnqgNOYExyeAo40z11Ue`,
	"topBarContentsImage": `j6l5xhczmHZ27bhRvHrG`,
	"groupTalkRightElementLink": `luZkuNnek0FDQPIPANM1`
};
export default ___CSS_LOADER_EXPORT___;
