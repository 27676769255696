import React, { Fragment, useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next/hooks';
// import bowser from 'bowser';

import { getNearAdElements } from '../../../util/ad/ad-visit';
import { useStore } from '../../../hook-store/store';

import { NODEURL } from '../../../App';

// import classes from './InPostsAdElements.module.css';

// window.BB = bowser;
// const ua = navigator.userAgent;
// const browser = bowser.getParser(ua);
// const parsedResult = browser.parsedResult;

const GetAdList = (props) =>
{
	// console.log('GetAdList-props', props);

	// const [ t ] = useTranslation('translation');

	const [ store, dispatch ] = useStore();

	// eslint-disable-next-line no-console
	// console.log('adList store-getAdList.js', store, parsedResult);

	useEffect(() =>
	{
		if (store.adStore.adList.length === 0)
		{
			// eslint-disable-next-line no-use-before-define
			getNearAdElementsHandler();
		}
	}, []);

	const getNearAdElementsHandler = async () =>
	{
		try
		{
			const adsData = await getNearAdElements(NODEURL, 'token');

			// eslint-disable-next-line no-console
			// console.log(adsData);
			// setAdList(adsData.data.ads);

			let adList = adsData.data.ads;

			// // filter not end ads and device size
			if (adList.length > 0)
			{
				// adList = adList.filter((ad) =>
				// {
				// 	return ad.end > Date.now();
				// 	// return ad.start < Date.now() && ad.end > Date.now();
				// });

				if (window.innerWidth <= 480)
				{
					adList = adList.filter((ad) =>
					{
						return ad.targetDevice !== 'desktop' && ad.end > Date.now();
					});
				}
				if (window.innerWidth > 480)
				{
					adList = adList.filter((ad) =>
					{
						return ad.targetDevice !== 'mobile' && ad.end > Date.now();
					});
				}
			}

			dispatch('SET_ADLIST', adList);
		}
		catch (err)
		{
			// eslint-disable-next-line no-console
			console.log(err);
		}
	};

	return (
		<Fragment />);
};

export default GetAdList;