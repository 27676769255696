// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #3b0062;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3b0062 transparent transparent transparent;
  border-color: #999 transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

.loaderSmall {
  display: inline-block;
  position: relative;
  /* width: 32rem;
  height: 32rem; */
  width: 1.5rem;
  height: 1.5rem;
}

.loaderSmall div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 3px;
  border: 3px solid #3b0062;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3b0062 transparent transparent transparent;
  border-color: #999 transparent transparent transparent;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./pages/StellarSpaceNews/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,4DAA4D;EAC5D,yDAAyD;EACzD,sDAAsD;AACxD;AACA;EACE,uBAAuB;AACzB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB;kBACgB;EAChB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,4DAA4D;EAC5D,yDAAyD;EACzD,sDAAsD;AACxD;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader {\n  display: inline-block;\n  position: relative;\n  width: 64px;\n  height: 64px;\n}\n.loader div {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 51px;\n  height: 51px;\n  margin: 6px;\n  border: 6px solid #3b0062;\n  border-radius: 50%;\n  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: #3b0062 transparent transparent transparent;\n  border-color: #999 transparent transparent transparent;\n}\n.loader div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n.loader div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n.loader div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n.loaderSmall {\n  display: inline-block;\n  position: relative;\n  /* width: 32rem;\n  height: 32rem; */\n  width: 1.5rem;\n  height: 1.5rem;\n}\n\n.loaderSmall div {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 20px;\n  height: 20px;\n  margin: 3px;\n  border: 3px solid #3b0062;\n  border-radius: 50%;\n  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: #3b0062 transparent transparent transparent;\n  border-color: #999 transparent transparent transparent;\n}\n\n@keyframes loader {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
