// const React = window.React = require('react');
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Img from 'react-cool-img';

import AdElementDisplay from '../AdElements/AdElememtDisplay/AdElementDisplay';
// import I18nextLSelector from './I18nextLSelector.jsx';
// import I18nextTest from '../I18nextTest.jsx';

import { useStore } from '../../hook-store/store';

import { ADDISPLAY_URL } from '../../App';

import '../../Island.css';
import '../../DarkMode.css';
import classes from './NewsList.module.css';
// import '../DarkModeToggle/DarkModeToggle.css';

// import classes from './NewsList.module.css';

const NewsListItem = (props) => {
    const { res, withAdIndex } = props;
    // console.log('PROPS in NewsList.jsx', props);
    const { t, i18n } = useTranslation();

    const [store, dispatch] = useStore();
    const windowValues = store.windowValues;

    let newsListItemBody;

    newsListItemBody = (
        <li
            style={{ fontSize: '', margin: '10px', padding: '1px 10px', listStyle: 'none' }}
            key={res.title}
        >
            <div
                style={{
                    fontSize: '24px',
                    fontWeight: 'bolder',
                    lineHeight: "1.2",
                    marginBottom: '1.5rem',
                    marginTop: '2rem',
                    color: 'var(--color)',
                }}
            >
                <a
                    href={res.url}
                    style={{ color: 'var(--color)', textDecoration: 'none' }}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                >
                    {res.title}
                </a>
            </div>

            <div style={{ display: 'flex' }}>
                <div style={{ width: '65%', wordWrap: 'break-word' }}>
                    <div style={{ fontSize: '18px', lineHeight:"1.2", fontWeight: '', paddingBottom: "1rem" }}>
                      {res.description}
                    </div>
                    {/* <div>{res.content}</div> */}
                    <div style={{ fontSize: '15px', fontWeight: '', paddingBottom: "0.5rem" }}>
                        published at:{' '}
                        {res.publishedAt && new Date(res.publishedAt).toLocaleString()}
                    </div>
                    <div style={{ fontSize: '15px', fontWeight: '' }}>
                        {res.author} ({res.source.name})
                    </div>
                    {/* <div>{res.source.id}</div> */}

                    {/* <div>{res.url}</div> */}
                    {/* <div>{res.urlToImage}</div> */}
                </div>
                <div style={{ width: '35%', maxWidth: '200px' }}>
                    {res.urlToImage && res.urlToImage.includes('https://') ? (
                        <a
                            href={res.url}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            style={{ margin: '5px' }}
                        >
                            {/* <img src={res.urlToImage} style={{ width: "100%", height: "" }} alt=""></img> */}
                            <Img
                                src={res.urlToImage}
                                style={{ width: '100%', height: '' }}
                                alt="no-img"
                            />
                            <noscript>
                                <Img
                                    src={res.urlToImage}
                                    style={{ width: '100%', height: '' }}
                                    alt="no-img"
                                />
                            </noscript>
                        </a>
                    ) : null}
                </div>
            </div>

            {withAdIndex && (
                <div
                    className="post__adContainer"
                    style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '30px' }}
                >
                    {/* withAdIndex: {withAdIndex} */}
                    {/* <div className="bodyInPostContainer">
          </div> */}
                    {/* <AdElementDisplay
            adType='feedList'
            adPlaceId={`stellar-space-news-${withAdIndex}`}
          /> */}

                    <div
                    // className={classes.iframeContainer}
                    >
                        {windowValues && windowValues.width >= 768 && (
                            <iframe
                                id="ad-iframe-inPosts-600x130"
                                title="ad-iframe-inPosts-600x130"
                                style={{
                                    width: '605px',
                                    height: '135px',
                                    overflow: 'hidden',
                                    border: 'none',
                                }}
                                src={
                                    ADDISPLAY_URL +
                                    `?adType=600x130&adPlaceId=${window.location.host}-1660706630287`
                                }
                                onLoad={() => {}}
                            />
                        )}
                        {windowValues && windowValues.width >= 480 && windowValues.width < 768 && (
                            <iframe
                                id="ad-iframe-inPosts-450x90"
                                title="ad-iframe-inPosts-450x90"
                                style={{
                                    width: '460px',
                                    height: '95px',
                                    overflow: 'hidden',
                                    border: 'none',
                                }}
                                src={
                                    ADDISPLAY_URL +
                                    `?adType=450x90&adPlaceId=${window.location.host}-1660706630286`
                                }
                                onLoad={() => {}}
                            />
                        )}
                        {windowValues && windowValues.width < 480 && (
                            <iframe
                                id="ad-iframe-inPosts-300x65"
                                title="ad-iframe-inPosts-300x65"
                                style={{
                                    width: '330px',
                                    height: '80px',
                                    overflow: 'hidden',
                                    border: 'none',
                                }}
                                src={
                                    ADDISPLAY_URL +
                                    `?adType=300x65&adPlaceId=${window.location.host}-1660706630285`
                                }
                                onLoad={() => {}}
                            />
                        )}
                    </div>
                </div>
            )}
        </li>
    );

    return <Fragment>{newsListItemBody}</Fragment>;
};

export default NewsListItem;
