import React from 'react';
// import { useState } from 'react';
// import BigNumber from 'bignumber.js';
// import { useTranslation } from 'react-i18next';

// import HeaderBackdrop from './HeaderBackdrop.jsx';
import ModalBackdropBase from './ModalBackdropBase.jsx';
// import LanguageSelector from './I18nextLSelector.jsx';


const ModalBase = props => {
  // console.log('ModalBase-props', props);

  // let headerModalStyle;
  // if (props.showModal) {
  //   headerModalStyle = {
  //     position: "fixed",
  //     width: "60%",
  //     // height: "30%",
  //     left: "20%",
  //     top: "8vh",
  //     background: "white",
  //     borderRadius: "5px",
  //     zIndex: "500",
  //     boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
  //     fontSize: "50px",
  //     fontWeight: "bolder",
  //     // opacity: props.showModal ? "1" : "0"
  //      padding: "10px"
  //   };
  // }

  let baseModal;

  if (props.showModal) {
    
    baseModal = (
      // <div style={props.baseModalStyle} >
      <div style={props.modalStyle} >
          {/* modal */}
          {props.modalContent}
        {props.children}
      </div>
    )
  }

  return (
    <div>
      {/* <HeaderBackdrop showModal={props.showModal} clicked={props.hideModalHandler} /> */}
       
       <ModalBackdropBase 
        showModal={props.showModal} 
        clicked={props.hideModalHandler} 
       /> 
      {baseModal}
    </div>
  )
};

export default ModalBase;