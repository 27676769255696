import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';

// import Button from '../Button/Button'
// import './DarkModeToggle.css';
// import { composeInitialProps } from 'react-i18next/hooks';

// import Toggle from './Toggle';

// const DarkModeToggle = () => {
//   const darkMode = useDarkMode(false);

//   return (
//     <div>
//       <button type="button" onClick={darkMode.disable}>
//         ☀
//       </button>
//       <Toggle checked={darkMode.value} onChange={darkMode.toggle} />
//       <button type="button" onClick={darkMode.enable}>
//         ☾
//       </button>
//     </div>
//   );
// };

const DarkModeToggle = (props) => {
    const { hideModalHandler } = props;

    const darkMode = useDarkMode(false);
    console.log(darkMode);

    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //   props.setDarkMode(darkMode.value);
    // },[darkMode.value]);

    const darkModeToggleHandler = () => {
        darkMode.toggle();

        if (hideModalHandler) {
            hideModalHandler();
        }
    };

    return (
        <div style={{ paddingBottom: '25px' }}>
            <span>
                {/* Dark Mode:  */}
                {t('userInfo.text26')}:
            </span>
            <span
                style={{ fontWeight: 'bolder', marginRight: '40px' }}
                onClick={darkModeToggleHandler}
            >
                {darkMode.value ? ' On' : ' Off'}
            </span>
            <button onClick={darkModeToggleHandler}>
                {/* {darkMode.value ? 'Disable' : 'Enable'} */}
                {darkMode.value ? `${t('userInfo.text27')}` : `${t('userInfo.text28')}`}
            </button>
            {/* <button onClick={darkMode.toggle}>Dark Mode Switch</button> */}
            {/* <div>some-text-in-darkmodetoggle</div> */}
        </div>
    );
};

export default DarkModeToggle;
