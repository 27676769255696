// const React = window.React = require('react');
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Img from 'react-cool-img';

// import I18nextLSelector from './I18nextLSelector.jsx';
// import I18nextTest from '../I18nextTest.jsx';
import AdTest from './AdTest';
import AdElementDisplay from '../AdElements/AdElememtDisplay/AdElementDisplay';
import AdElementTime from '../AdElements/AdElememtDisplay/AdElementTime';
import Loader from '../Loader/Loader';
import Ellipsis from '../Ellipsis.jsx';
import Loading from '../Loading.jsx';
import ModalBase from './ModalBase.jsx';
import NewsListItem from './NewsListItem';
import NewsModal from './NewsModal.jsx';
// import SubNavigation from './SubNavigaton.jsx';
import Paginator from '../Paginator/Paginator';
import Spinner from '../Spinner.jsx';

import { useStore } from '../../hook-store/store';

import { createWithAdIndexList } from '../../util/ad/ad-visit';

import { fbUrl, nwUrl, ADDISPLAY_URL } from '../../App';

import '../../Island.css';
import '../../DarkMode.css';
// import '../DarkModeToggle/DarkModeToggle.css';

import classes from './NewsList.module.css';

import Rewards from '../../../rewards/Rewards.tsx';


const _ = require('lodash');

// require('dotenv').config()

// // Firebase App (the core Firebase SDK) is always required and
// // must be listed before other Firebase SDKs
// var firebase = require("firebase/app");

// // Add the Firebase products that you want to use
// require("firebase/auth");
// require("firebase/firestore");

const loadingModalStyle = {
    position: 'fixed',
    width: '95%',
    height: '80%',
    left: '2.5%',
    top: '10vh',
    background: 'white',
    borderRadius: '5px',
    zIndex: '500',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
    fontSize: '',
    fontWeight: 'bolder',
    // opacity: props.showModal ? "1" : "0",
    // opacity: "0.8",
    opacity: '0.3',
    padding: '10px',
};

const tabStyle = {
    borderColor: 'lightgray',
    fontWeight: 'bolder',
    // padding: "10px" ,
    padding: '7.5px',
    cursor: 'pointer',
};

const selectedTabStyle = {
    borderColor: 'lightgray',
    fontWeight: 'bolder',
    // padding: "10px",
    padding: '7.5px',
    textDecoration: 'underline darkgray 4px',
};

const NewsList = (props) => {
    // console.log('PROPS in NewsList.jsx', props);
    const { t, i18n } = useTranslation();

    const [store, dispatch] = useStore();
    const windowValues = store.windowValues;
    // console.log('windowValues', windowValues);

    const [btNewsApi, setBtNewsApi] = useState([]);
    const [apNewsApi, setApNewsApi] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newsListName, setNewsListName] = useState('');
    const [newsList, setNewsList] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [clickCount, setClickCount] = useState(0);
    const [selectedTab, setSelectedTab] = useState('');

    const [adIndexes, setAdIndexes] = useState([]);

    // console.log('btNewsApi in useState', btNewsApi);
    // console.log('apNewsApi in useState', apNewsApi);
    // console.log('newsListName', newsListName);
    // console.log('newsList', newsList);

    const perPage = 20;

    useEffect(() => {
        const loadingModalContent = (
            <div>
                <Loading size="large">
                    <div style={{ fontSize: '', textAlign: 'center' }}>
                        ...
                        <Ellipsis />
                        <Spinner />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Loader />
                    </div>
                </Loading>
            </div>
        );
        setLoadingMessage(
            <ModalBase
                showModal={true}
                modalStyle={loadingModalStyle}
                modalContent={loadingModalContent}
            />,
        );

        let uniqueNewsList;

        if (!newsListName) {
            let paramPath = `stellar-and-cryp.json`;

            if (lnParam === '-es') {
                paramPath = `cryp${lnParam}.json`;
            }

            if (lnParam === '-zh') {
                paramPath = `cryp${lnParam}.json`;
            }

            // axios.get(fbUrl + `/news-api-test/stellar-and-cryp.json?auth=` + props.d.driverFbUser.fbUserToken)
            // axios.get(nwUrl + `/news-api-test/stellar-and-cryp.json`)
            axios
                .get(nwUrl + `/news-api-test/${paramPath}`)
                .then((result) => {
                    uniqueNewsList = makeUniqueList(result.data, 'urlToImage');
                    uniqueNewsList = makeUniqueList(result.data, 'title');
                    // console.log('ineffectnewslist', result.data);
                    console.log('i18n language', props.i18n.language);
                    console.log('newslistname', newsListName);
                    console.log('uniqueNewsList', uniqueNewsList);

                    setNewsList(uniqueNewsList);
                    setLoadingMessage('');
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingMessage('');
                });
        } else {
            // axios.get(fbUrl + `/news-api-test/${newsListName}.json?auth=` + props.d.driverFbUser.fbUserToken)
            axios
                .get(nwUrl + `/news-api-test/${newsListName}.json`)
                .then((result) => {
                    uniqueNewsList = makeUniqueList(result.data, 'urlToImage');
                    uniqueNewsList = makeUniqueList(result.data, 'title');
                    // console.log('ineffectnewslist', result.data);
                    console.log('i18n language', props.i18n.language);
                    console.log('newslistname', newsListName);
                    console.log('uniqueNewsList', uniqueNewsList);

                    setNewsList(uniqueNewsList);
                    setLoadingMessage('');
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingMessage('');
                });
        }

        // axios.get(fbUrl + '/news-api-test/headline/hdGeneral.json')
        // .then(result => {
        //   // console.log('axios get result apple',result.data);
        //   setApNewsApi(result.data);
        // })
        // .catch(err => {
        //   console.log(err);
        // })
    }, [newsListName]);

    useEffect(() => {
        if (newsList.length > 0) {
            setAdIndexes(createWithAdIndexList());
        }
    }, [newsList, clickCount]);

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const tabSelectParam = queryParams.get('tabselect');

        if (tabSelectParam && tabSelectParam === 'reward-info') {
            setSelectedTab('reward-info');
        }
    },[]);

    const newsListSelectHandler = (nName) => {
        setNewsListName(nName);
    };

    const showModalHandler = () => {
        setShowModal(!showModal);
    };

    const hideModalHandler = () => {
        setShowModal(false);
    };

    const makeUniqueList = (list, select) => {
        return _.uniqBy(list, select);
    };

    let showNewsList;
    if (newsList.length > perPage) {
        showNewsList = newsList.slice(0, perPage);
        showNewsList = newsList.slice(0, (clickCount + 1) * perPage);
    }
    if (newsList.length < perPage || newsList.length < (clickCount + 1) * perPage) {
        showNewsList = newsList.slice(0, newsList.length);
    }

    console.log(
        clickCount,
        clickCount * perPage,
        (clickCount + 1) * perPage,
        Math.ceil(newsList.length / perPage),
        newsList.length,
    );

    const pageStart = clickCount * perPage;
    const pageEnd = (clickCount + 1) * perPage;

    let newsListArray;

    if (newsList.length > 0) {
        let adIndexList = adIndexes;
        console.log(adIndexList);

        newsListArray = (
            <ul style={{ padding: '0' }}>
                {/* {showNewsList.map(res => { */}
                {newsList.slice(pageStart, pageEnd).map((res, index) => {
                    const pagePostNum = pageEnd - pageStart;

                    if (pagePostNum <= 3) {
                        adIndexList = [];
                    }

                    if (pagePostNum > 3 && pagePostNum < 5) {
                        adIndexList = [pagePostNum - 1];
                    }

                    const isAdIndex = adIndexList.find((ix) => {
                        return ix === index;
                    });

                    const indexValue = isAdIndex && `page-${clickCount + 1}-${index}`;

                    // console.log(isAdIndex, indexValue);
                    // console.log('res title', res.title)
                    return (
                        <span>
                            <NewsListItem res={res} withAdIndex={indexValue} />
                        </span>
                    );
                })}
            </ul>
        );
    }

    let lnParam = '-en';
    if (props.i18n.language === 'en' || props.i18n.language === 'Language') {
        lnParam = '-en';
    }
    if (props.i18n.language === 'hi') {
        lnParam = '-en';
    }
    if (
        props.i18n.language === 'zh-HK' ||
        props.i18n.language === 'zh-CH' ||
        props.i18n.language === 'zh-CN' ||
        props.i18n.language === 'zh-TW' ||
        props.i18n.language === 'zh'
    ) {
        lnParam = '-' + props.i18n.language.split('-')[0];
    }
    if (props.i18n.language === 'es') {
        lnParam = '-' + props.i18n.language;
    }
    if (props.i18n.language === 'ar') {
        lnParam = '-' + props.i18n.language;
    }

    let lnHdParam = '';
    if (props.i18n.language === 'en' || props.i18n.language === 'Language') {
        lnHdParam = '-us';
    }
    if (props.i18n.language === 'hi') {
        lnHdParam = '-in';
    }
    if (props.i18n.language === 'zh-HK') {
        lnHdParam = '-hk';
    }
    if (props.i18n.language === 'zh-CH') {
        lnHdParam = '-cn';
    }
    if (props.i18n.language === 'zh-CN') {
        lnHdParam = '-cn';
    }
    if (props.i18n.language === 'zh') {
        lnHdParam = '-cn';
    }
    if (props.i18n.language === 'es') {
        lnHdParam = '';
    }

    const adTestBody = (
        <div className="so-back islandBack islandBack--t">
            <AdTest />
        </div>
    );

    const newsListBody = (
        <div className="so-back islandBack islandBack--t">
            <div className={classes.iframeContainer}>
                {windowValues && windowValues.width >= 768 && (
                    <iframe
                        id="ad-iframe-top-600x130"
                        title="ad-iframe-top-600x130"
                        style={{
                            width: '605px',
                            height: '135px',
                            overflow: 'hidden',
                            border: 'none',
                        }}
                        src={
                            ADDISPLAY_URL +
                            `?adType=600x130&adPlaceId=${window.location.host}-1660706630287`
                        }
                        onLoad={() => {}}
                    />
                )}
                {windowValues && windowValues.width >= 480 && windowValues.width < 768 && (
                    <iframe
                        id="ad-iframe-top-450x90"
                        title="ad-iframe-top-450x90"
                        style={{
                            width: '460px',
                            height: '95px',
                            overflow: 'hidden',
                            border: 'none',
                        }}
                        src={
                            ADDISPLAY_URL +
                            `?adType=450x90&adPlaceId=${window.location.host}-1660706630286`
                        }
                        onLoad={() => {}}
                    />
                )}
                {windowValues && windowValues.width < 480 && (
                    <iframe
                        id="ad-iframe-top-300x65"
                        title="ad-iframe-top-300x65"
                        style={{
                            width: '330px',
                            height: '80px',
                            overflow: 'hidden',
                            border: 'none',
                        }}
                        src={
                            ADDISPLAY_URL +
                            `?adType=300x65&adPlaceId=${window.location.host}-1660706630285`
                        }
                        onLoad={() => {}}
                    />
                )}
            </div>

            <div style={{ width: "95%", maxWidth: '900px', margin: '0 auto', paddingTop: '40px' }}>
                <button
                    style={selectedTab === 'crypto' ? selectedTabStyle : tabStyle}
                    onClick={() => {
                        newsListSelectHandler(`cryp${lnParam}`);
                        hideModalHandler();
                        setClickCount(0);
                        setSelectedTab('crypto');
                    }}
                >
                    Crypto
                </button>
                <button
                    style={selectedTab === 'market' ? selectedTabStyle : tabStyle}
                    onClick={() => {
                        newsListSelectHandler(`cryp-market`);
                        hideModalHandler();
                        setClickCount(0);
                        setSelectedTab('market');
                    }}
                >
                    {t('newsTab01', 'Markets')}
                </button>
                <button
                    style={selectedTab === 'coins' ? selectedTabStyle : tabStyle}
                    onClick={() => {
                        showModalHandler();
                        setClickCount(0);
                        setSelectedTab('coins');
                    }}
                >
                    Coins
                </button>
                <button
                    style={selectedTab === 'blockchain' ? selectedTabStyle : tabStyle}
                    onClick={() => {
                        newsListSelectHandler(`blockchain${lnParam}`);
                        hideModalHandler();
                        setClickCount(0);
                        setSelectedTab('blockchain');
                    }}
                >
                    {t('newsTab02', 'Blockchain')}
                </button>
                <button
                    style={selectedTab === 'other' ? selectedTabStyle : tabStyle}
                    onClick={() => {
                        newsListSelectHandler(`headline/hd-general${lnHdParam}`);
                        hideModalHandler();
                        setClickCount(0);
                        setSelectedTab('other');
                    }}
                >
                    {t('newsTab03', 'Other')}
                </button>
                <button
                    style={selectedTab === 'reward-info' ? selectedTabStyle : tabStyle}
                    onClick={() => {
                        // newsListSelectHandler(`headline/hd-general${lnHdParam}`);
                        hideModalHandler();
                        setClickCount(0);
                        setSelectedTab('reward-info');
                    }}
                >
                  {/* <Link to="rewards" style={{textDecoration:"none", color:"var(--color)"}}>Reward-info</Link> */}
                  {t('newsTab04', 'Reward info')}
                </button>
            </div>

            <div
                className="island"
                style={{ paddingTop: '5px', width: "95%", maxWidth: '900px', margin: '0 auto' }}
            >
                <div>
                    {showModal ? (
                        <NewsModal
                            showModalHandler={showModalHandler}
                            newsListSelectHandler={newsListSelectHandler}
                            i18n={props.i18n}
                        ></NewsModal>
                    ) : null}

                    {/* <input style={searchInputStyle} onChange={searchInputHandler} /> */}

                    {/* {newsListArray} */}
                    
                    {/* {btNewsList} */}
                    {/* {apNewsList} */}

                    {selectedTab !== 'reward-info' && (
                      <div>
                        {newsListArray}
                        
                        <Paginator
                            onPrevious={() => {
                                setClickCount(clickCount - 1);
                            }}
                            onNext={() => {
                                setClickCount(clickCount + 1);
                            }}
                            lastPage={Math.ceil(newsList.length / perPage)}
                            // lastPage={3}
                            currentPage={clickCount + 1}
                            // currentPage={1}
                            getStoreCurrentPage={() => {}}
                            posts={newsList}
                        ></Paginator>
                      </div>
                    )}

                    {selectedTab === 'reward-info' && (
                      <Rewards />
                    )}

                 
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {/* smatyads */}
            {/* <div style={{textAlign: "center"}}>
        <div id="block_22674" style={{marginTop:"50px", marginBottom:"0px"}}></div>
      </div> */}

            {loadingMessage}

            {/* comment out when deploy and deploy */}
            {/* {adTestBody} */}

            {newsListBody}

            {/* smartyads */}
            {/* <div style={{display:"flex", justifyContent:"center", marginBottom:"50px"}}>
        <div id="block_22675"></div>
        <div id="block_22676"></div>
      </div> */}

              {/* <Rewards /> */}
        </div>
    );
};

export default NewsList;
