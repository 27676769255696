import * as React from 'react';
import { render } from 'react-dom';
import '@babel/polyfill';
import App from './App';

// import configureUiStore from './hook-store/ui-store';
import configureUiStore from './pages/StellarSpaceNews/hook-store/ui-store';
// import configureAdStore from './hook-store/ad-store';
import configureAdStore from './pages/StellarSpaceNews/hook-store/ad-store';

configureUiStore();
configureAdStore();

const rootEl = document.getElementById('root');

render(<App />, rootEl);
