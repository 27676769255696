import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Question from '../../../../../common/basics/Question';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Questions = () => {
    const { t, i18n } = useTranslation();

    const qText1 = t('rewardInfo04',`Link to SDEX markets and AMM liquidity pools`);
    const qText2 = t('rewardInfo05',`How do I participate in earning AMM daily reward?`);        
    const qText3 = t('rewardInfo06',`AMM rewards are earned through liquidity providing on the above markets. These rewards are distributed based on a user's contribution to AMM liquidity pools.`);
    const stellarSpaceLink = (
        <a
            href="https://www.spaceeight.work"
            target="_blank"
            rel="nofollow noopener noreferrer"
            style={{
                fontWeight: 'bold',
                color: 'inherit',
                textDecoration: 'underline',
            }}
        >
            {/* Link to SDEX and AMM liquidity pools */}
            {qText1}
        </a>
    );

    return (
        <Container>
            <Question
                question={'How can I get involved with AQUA rewards?'}
                answer={
                    <span>
                        An AQUA trustline needs to be added to the user's Stellar wallet. Trustlines
                        allow a user to receive & hold specific Stellar assets within their wallets.
                        Once a user has a Stellar wallet and an AQUA trustline enabled they can
                        start earning rewards through SDEX market making or AMM liquidity providing.
                        {' '}
                        {stellarSpaceLink}
                    </span>
                }
            />
            <Question
                question={'How do I participate in earning SDEX daily rewards?'}
                answer={
                    <span>
                        SDEX rewards are earned through market making on the above markets on
                        Stellar Decentralized Exchange (SDEX). Market making happens through the
                        creation of buy & sell offers, with orders sitting on the order books ready
                        for other users to take at a later time.
                        {/* For SDEX market making you can use
                        platforms like LOBSTR, StellarX, StellarTerm or market-making bots like Kelp
                        bot. */}{' '}
                        {stellarSpaceLink}
                    </span>
                }
            />
            <Question
                // question={'How do I participate in earning AMM daily reward?'}
                question={qText2}
                answer={
                    <span>
                        {/* AMM rewards are earned through liquidity providing on the above markets
                        through Stellar AMMs. These rewards are distributed based on a user's
                        contribution to AMM pools. */}
                        {/* You can get access to AMMs on Stellar using
                        products like StellarX, Stellarport, or Lumenswap. */}
                        {qText3} {stellarSpaceLink}
                    </span>
                }
            />
        </Container>
    );
};

export default Questions;
