import axios from 'axios';
import { Rewards, TotalRewards } from '../../vote/api/types';
import { ListResponse } from '../../../store/assetsStore/types';

//// const rewardsApi = 'https://reward-api.aqua.network/api/rewards/';

//// for local dev
// const rewardsApi = 'http://localhost:4000/aqua-reward/';

//// for test deploy
// const rewardsApi = 'https://dokmp18-bc.spaceeight.net/aqua-reward/';

//// for deploy
const rewardsApi = 'https://dokmp18-b.spaceeight.net/aqua-reward/';



// export const getTotalRewards = (): Promise<TotalRewards> => {
//     return axios.get<TotalRewards>(`${rewardsApi}total/`).then(({ data }) => {
//         return data;
//     });
// };

export const getTotalRewards = async (): any => {
    try {
        const lsTotalRewards = localStorage.getItem('aquaTotalRewards');

        if (lsTotalRewards) {
            const parsedData = JSON.parse(lsTotalRewards);

            const lastUpdateTime = new Date(parsedData.lastupdated).getTime();

            if (lastUpdateTime && lastUpdateTime < Date.now() - 1000 * 60 * 60 * 4) {
                const result = await axios.get<TotalRewards>(`${rewardsApi}total/`);

                localStorage.setItem('aquaTotalRewards', JSON.stringify(result.data));
                return result.data;
            }

            return parsedData;
        } else {
            const result = await axios.get<TotalRewards>(`${rewardsApi}total/`);

            localStorage.setItem('aquaTotalRewards', JSON.stringify(result.data));
            return result.data;
        }
    } catch (err) {
        console.log(err);
    }
};

export enum RewardsSort {
    sdexUp = '-daily_sdex_reward',
    sdexDown = 'daily_sdex_reward',
    ammUp = '-daily_amm_reward',
    ammDown = 'daily_amm_reward',
    totalUp = '-daily_total_reward',
    totalDown = 'daily_total_reward',
}

// export const getRewards = (sort): Promise<Rewards[]> => {
//     return axios
//         .get<ListResponse<Rewards>>(`${rewardsApi}?ordering=${sort}&page=1&page_size=200`)
//         .then(({ data }) => data.results);
// };

export const getRewards = async (sort): any => {
    try {
        // console.log('sort', sort);
        const lsRewards = localStorage.getItem('aquaRewards');

        if (lsRewards) {
            const parsedData = JSON.parse(lsRewards);

            const lastUpdateTime = new Date(parsedData.results[0].last_updated).getTime();

            if (lastUpdateTime && lastUpdateTime < Date.now() - 1000 * 60 * 60 * 4) {
                const result = await axios.get(
                    // `${rewardsApi}?ordering=${sort}&page=1&page_size=200`,
                    `${rewardsApi}?page=1&page_size=200`,
                );

                if (result.data && result.data.results) {
                    const storeData = {
                        ...result.data,
                        getTime: Date.now(),
                    };

                    localStorage.setItem('aquaRewards', JSON.stringify(storeData));
                    return result.data.results;
                }
            }

            if (sort.startsWith('-')) {
                return parsedData.results;
            }

            return parsedData.results.reverse();
        } else {
            const result = await axios.get(
                // `${rewardsApi}?ordering=${sort}&page=1&page_size=200`
                `${rewardsApi}?page=1&page_size=200`,
            );

            if (result.data && result.data.results) {
                const storeData = {
                    ...result.data,
                    getTime: Date.now(),
                };

                localStorage.setItem('aquaRewards', JSON.stringify(storeData));
                return result.data.results;
            }
        }
    } catch (err) {
        console.log(err);
    }
};
