// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.JJWqZclZ3RCu8f9tPzM2 {
  color: red;
}

.ctLC8cnoRtNxPfKsZz53 {
  width: 100%
}

.BSIyrYabRzR5XMKau2au {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .ctLC8cnoRtNxPfKsZz53 {
    /* margin-right: 350px; */
    margin: 0 auto;
    /* max-width: 40rem; */
  }
}`, "",{"version":3,"sources":["webpack://./pages/StellarSpaceNews/components/news-list/NewsList.module.css"],"names":[],"mappings":";AACA;EACE,UAAU;AACZ;;AAEA;EACE;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE;IACE,yBAAyB;IACzB,cAAc;IACd,sBAAsB;EACxB;AACF","sourcesContent":["\n.testCss {\n  color: red;\n}\n\n.newsContainer {\n  width: 100%\n}\n\n.iframeContainer {\n  display: flex;\n  justify-content: center;\n  padding-bottom: 1rem;\n}\n\n@media (min-width: 768px) {\n  .newsContainer {\n    /* margin-right: 350px; */\n    margin: 0 auto;\n    /* max-width: 40rem; */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"testCss": `JJWqZclZ3RCu8f9tPzM2`,
	"newsContainer": `ctLC8cnoRtNxPfKsZz53`,
	"iframeContainer": `BSIyrYabRzR5XMKau2au`
};
export default ___CSS_LOADER_EXPORT___;
