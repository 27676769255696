import React, { Fragment, Suspense, useEffect} from 'react';
// import {
//   BrowserRouter,
//   Routes,
//   Route,
// } from "react-router-dom";
// import axios from 'axios';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';
import DarkModeToggle from './components/DarkModeToggle/DarkModeToggle';
import GetAd from './components/AdElements/GetAds/GetAds';
import GetWindowData from './components/GetWindowData';
import NewsList from './components/news-list/NewsList';
import Header from './components/header/Header.jsx';

import HeadMetaTag from './components/UI/HeadMetaTag.jsx';
// import Rewards from './components/Aqua/rewards/Rewards.jsx';

// import logo from './logo.svg';
import './App.css';
import './Island.css';


// REACT_APP_DEV_NODEURL='http://localhost:4000'
// REACT_APP_NODEURL='https://dokmp18-a.spaceeight.net'

// REACT_APP_DEV_ADNETWORKLINK_URL=http://localhost:3002
// REACT_APP_TEST_ADNETWORKLINK_URL=https://adnet-link-frontend-app-test.web.app
// REACT_APP_ADNETWORKLINK_URL=https://adnet-link.spaceeight.net

// REACT_APP_DEV_ADDISPLAY_URL=http://localhost:3003
// REACT_APP_TEST_ADDISPLAY_URL=https://adnetwork-adservice-adpage-tes.web.app
// REACT_APP_ADDISPLAY_URL=https://adnetwork-adserviceadpage.spaceeight.net



// export const nwUrl = process.env.REACT_APP_NEWSURL;
export const nwUrl = 'https://nw-ap-190920-dev.firebaseio.com';


////// for local dev urls
// export const NODEURL = 'http://localhost:4000';
// export const ADNETWORKLINK_URL = 'http://localhost:3002';
// export const ADDISPLAY_URL = 'http://localhost:3003';
// export const LIQUIDITY_POOL2_URL = 'https://stellar-aqua-amm.web.app';
// export const BLEND_SUPPLY_URL = 'https://blend-ui-supply.vercel.app';

////// for test urls
// export const NODEURL = 'https://dokmp18-bc.spaceeight.net';
// export const ADNETWORKLINK_URL = 'https://adnet-link-frontend-app-test.web.app';
// export const ADDISPLAY_URL = 'https://adnetwork-adservice-adpage-tes.web.app';
// export const LIQUIDITY_POOL2_URL = 'https://stellar-aqua-amm.web.app';
// export const BLEND_SUPPLY_URL = 'https://blend-ui-supply.vercel.app';


///// for deploy urls
export const NODEURL = 'https://dokmp18-b.spaceeight.net';
export const ADNETWORKLINK_URL = 'https://adnet-link.spaceeight.net';
export const ADDISPLAY_URL = 'https://adnetwork-adserviceadpage.spaceeight.net';
export const LIQUIDITY_POOL2_URL = 'https://stellar-aqua-amm.web.app';
export const BLEND_SUPPLY_URL = 'https://blend-ui-supply.vercel.app';





// const routes = (
//   <div>
//     <Routes>
//       <Route path="/" element={<NewsList  i18n={i18n}/>} />
//     </Routes>
//   </div>
// );

function App() {
  return (
    <Fragment>
        <Suspense fallback={null}>
          <I18nextProvider i18n={i18n}>
            <Header />

            {/* <Rewards /> */}

            {/* StellarSpace News */}

            {/* {routes} */}
            <NewsList i18n={i18n}/>

            <GetWindowData />
            {/* <GetAd /> */}
            <DarkModeToggle />
            <HeadMetaTag />

          </I18nextProvider>
        </Suspense>
    </Fragment>
  );
}

export default App;
